<template>
	<S2SCard title="My Connections">
		<v-layout row wrap :slot="$vuetify.breakpoint.smAndUp ? 'toolbar-content' : 'default'" align-center justify-end>
			<v-btn
				color="primary"
				:to="{ name: 'organisation-connection-create' }"
				:block="$vuetify.breakpoint.xsOnly"
				:class="$vuetify.breakpoint.xsOnly ? 'mb-2' : 'mr-2'"
				>Add</v-btn
			>
			<v-btn color="accent" :to="{ name: 'organisation-pending-connections' }" :block="$vuetify.breakpoint.xsOnly">Pending Invites</v-btn>
		</v-layout>

		<v-item-group v-model="toggle_id" mandatory>
			<v-container>
				<v-row>
					<v-flex xs12 sm3 v-for="status in connectionTypes" :key="status.id" px-2 py-2>
						<v-item v-slot:default="{ active, toggle }">
							<v-card :color="active ? 'primary' : ''" class="d-flex align-center" dark height="50" @click="toggle">
								<v-scroll-y-transition>
									<div v-if="active" class="display-5 flex-grow-1 text-center">{{ status.name }}</div>
									<div v-else-if="!active" class="display-5 flex-grow-1 text-center">{{ status.name }}</div>
								</v-scroll-y-transition>
							</v-card>
						</v-item>
					</v-flex>
				</v-row>
			</v-container>
		</v-item-group>

		<v-text-field label="Filter Connections" v-model="searchVal"></v-text-field>

		<v-data-table
			ref="dataTable"
			:headers="headers"
			:items="filterConnections(toggle_id)"
			:loading="loading"
			:options.sync="pagination"
			:search="searchVal"
			class="elevation-1"
		>
			<template v-slot:item.removeConnection="{ item }">
				<td class="text-xs-left">
					<v-icon medium @click="deleteConnections(item.id)">delete</v-icon>
				</td>
			</template>
		</v-data-table>
	</S2SCard>
</template>

<script>
import Vue from "vue";
import { Utils } from "@/utils/";

export default Vue.extend({
	name: "Connections",

	computed: {
		loading: function() {
			return this.$store.state.organisations.connections.loading;
		},
		connections: function() {
			return this.$store.getters["organisations/connections/connections"];
		},
		connectionTypes: function() {
			return this.$store.getters["organisations/connections/connectionTypes"];
		},
		filterConnections: function() {
			return this.$store.getters["organisations/connections/filterConnections"];
		}
	},

	data: function() {
		return {
			Utils: Utils,
			editModel: {},
			searchVal: "",

			toggle_id: 0,

			headers: [
				{ text: "Connection Id", value: "id" },
				{ text: "Customer Code", value: "customerCode" },
				{ text: "Customer Name", value: "customerName" },
				{ text: "Types", value: "types" },
				{ text: "Contact Name", value: "contactName" },
				{ text: "Contact Number", value: "contactNumber" },
				{ text: "Status", value: "status" },
				{ text: "Remove Connection", value: "removeConnection" }
			],

			pagination: {
				page: 1,
				itemsPerPage: 10 // -1 for All
			}
		};
	},

	mounted: function() {
		this.$store.dispatch("organisations/connections/fetchConnectionTypes");
		this.$store.dispatch("organisations/connections/fetchConnections");
	},

	methods: {
		formatColumn(header, item) {
			return Utils.formatColumn(header, item);
		},
		deleteConnections(id) {
			this.$store.dispatch("organisations/connections/deleteConnections", id);
		}
	}
});
</script>
